import { useEffect, useState, useCallback } from "react";
import { BoxScore, getBoxScore } from "../lib/helpers";

export { BoxScore };

export function useBoxScores() {
  const [boxScores, setBoxScores] = useState<BoxScore[]>([]);

  const refetch = useCallback(() => {
    return getBoxScore().then(setBoxScores);
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return { data: boxScores, refetch };
}

export interface Player {
  seasonId: number;
  id: number;
  firstName: string;
  fullName: string;
  lastName: string;
  jerseyNumber?: any;
  proTeam: string;
  proTeamAbbreviation: string;
  defaultPosition: string;
  eligiblePositions: string[];
  availabilityStatus: string;
  isDroppable: boolean;
  isInjured: boolean;
  injuryStatus: string;
}

export interface PointBreakdown {
  defensive0PointsAllowed?: number;
  defensive100To199YardsAllowed?: number;
  defensive14To17PointsAllowed?: number;
  defensive1To6PointsAllowed?: number;
  defensive200To299YardsAllowed?: number;
  defensive28To34PointsAllowed?: number;
  defensive350To399YardsAllowed?: number;
  defensive35To45PointsAllowed?: number;
  defensive400To449YardsAllowed?: number;
  defensive450To499YardsAllowed?: number;
  defensive500To549YardsAllowed?: number;
  defensive7To13PointsAllowed?: number;
  defensiveInterceptions?: number;
  defensiveOver550YardsAllowed?: number;
  defensiveSacks?: number;
  passingInterceptions?: number;
  passingTouchdowns?: number;
  receivingTouchdowns?: number;
  rushingTouchdowns?: number;
  usesPoints: boolean;
}

export interface ProjectedPointBreakdown {
  defensive0PointsAllowed?: number;
  defensive100To199YardsAllowed?: number;
  defensive14To17PointsAllowed?: number;
  defensive1To6PointsAllowed?: number;
  defensive200To299YardsAllowed?: number;
  defensive28To34PointsAllowed?: number;
  defensive350To399YardsAllowed?: number;
  defensive35To45PointsAllowed?: number;
  defensive400To449YardsAllowed?: number;
  defensive450To499YardsAllowed?: number;
  defensive500To549YardsAllowed?: number;
  defensive7To13PointsAllowed?: number;
  defensiveBlockedKickForTouchdowns?: number;
  defensiveBlockedKicks?: number;
  defensiveFumbles?: number;
  defensiveInterceptions?: number;
  defensiveOver550YardsAllowed?: number;
  defensiveSacks?: number;
  defensiveSafeties?: number;
  fumbleReturnTouchdown?: number;
  interceptionReturnTouchdown?: number;
  kickoffReturnTouchdown?: number;
  lostFumbles?: number;
  madeExtraPoints?: number;
  madeFieldGoalsFrom40To49?: number;
  madeFieldGoalsFromUnder40?: number;
  missedFieldGoals?: number;
  passing2PtConversions?: number;
  passingInterceptions?: number;
  passingTouchdowns?: number;
  puntReturnTouchdown?: number;
  receiving2PtConversions?: number;
  receivingTouchdowns?: number;
  rushing2PtConversions?: number;
  rushingTouchdowns?: number;
  usesPoints: boolean;
}

export interface RawStats {
  defensive0PointsAllowed?: number;
  defensive100To199YardsAllowed?: number;
  defensive14To17PointsAllowed?: number;
  defensive1To6PointsAllowed?: number;
  defensive200To299YardsAllowed?: number;
  defensive28To34PointsAllowed?: number;
  defensive350To399YardsAllowed?: number;
  defensive35To45PointsAllowed?: number;
  defensive400To449YardsAllowed?: number;
  defensive450To499YardsAllowed?: number;
  defensive500To549YardsAllowed?: number;
  defensive7To13PointsAllowed?: number;
  defensiveInterceptions?: number;
  defensiveOver550YardsAllowed?: number;
  defensiveSacks?: number;
  passingInterceptions?: number;
  passingTouchdowns?: number;
  passingYards?: number;
  receivingReceptions?: number;
  receivingTouchdowns?: number;
  receivingYards?: number;
  rushingTouchdowns?: number;
  rushingYards?: number;
  usesPoints: boolean;
}

export interface ProjectedRawStats {
  usesPoints: boolean;
  rushingYards?: number;
  rushingTouchdowns?: number;
  rushing2PtConversions?: number;
  receivingYards?: number;
  receivingTouchdowns?: number;
  receiving2PtConversions?: number;
  receivingReceptions?: number;
  lostFumbles?: number;
  passingYards?: number;
  passingTouchdowns?: number;
  passing2PtConversions?: number;
  passingInterceptions?: number;
  defensive0PointsAllowed?: number;
  defensive1To6PointsAllowed?: number;
  defensive7To13PointsAllowed?: number;
  defensive14To17PointsAllowed?: number;
  defensiveBlockedKickForTouchdowns?: number;
  defensiveInterceptions?: number;
  defensiveFumbles?: number;
  defensiveBlockedKicks?: number;
  defensiveSafeties?: number;
  defensiveSacks?: number;
  kickoffReturnTouchdown?: number;
  puntReturnTouchdown?: number;
  fumbleReturnTouchdown?: number;
  interceptionReturnTouchdown?: number;
  defensive28To34PointsAllowed?: number;
  defensive35To45PointsAllowed?: number;
  defensive100To199YardsAllowed?: number;
  defensive200To299YardsAllowed?: number;
  defensive350To399YardsAllowed?: number;
  defensive400To449YardsAllowed?: number;
  defensive450To499YardsAllowed?: number;
  defensive500To549YardsAllowed?: number;
  defensiveOver550YardsAllowed?: number;
  madeFieldGoalsFrom50Plus?: number;
  madeFieldGoalsFrom40To49?: number;
  madeFieldGoalsFromUnder40?: number;
  missedFieldGoals?: number;
  madeExtraPoints?: number;
  missedExtraPoints?: number;
}

export interface BoxScoreRosterEntry {
  player: Player;
  position: string;
  totalPoints: number;
  pointBreakdown: PointBreakdown;
  projectedPointBreakdown: ProjectedPointBreakdown;
  rawStats: RawStats;
  projectedRawStats: ProjectedRawStats;
}

export type BoxScoreRoster = BoxScoreRosterEntry[];

export interface BoxScore {
  homeScore: number;
  homeTeamId: number;
  homeRoster: BoxScoreRoster;
  awayScore: number;
  awayTeamId: number;
  awayRoster: BoxScoreRoster;
}

export interface TeamRoster {
  seasonId: number;
  id: number;
  firstName: string;
  fullName: string;
  lastName: string;
  jerseyNumber?: any;
  proTeam: string;
  proTeamAbbreviation: string;
  defaultPosition: string;
  eligiblePositions: string[];
  averageDraftPosition: number;
  auctionValueAverage: number;
  percentChange: number;
  percentStarted: number;
  percentOwned: number;
  availabilityStatus: string;
  isDroppable: boolean;
  isInjured: boolean;
  injuryStatus: string;
}

export interface Team {
  leagueId: number;
  seasonId: number;
  id: number;
  abbreviation: string;
  name: string;
  roster: TeamRoster[];
  wins: number;
  losses: number;
  ties: number;
  divisionWins: number;
  divisionLosses: number;
  divisionTies: number;
  homeWins: number;
  homeLosses: number;
  homeTies: number;
  awayWins: number;
  awayLosses: number;
  awayTies: number;
  totalPointsScored: number;
  regularSeasonPointsFor: number;
  regularSeasonPointsAgainst: number;
  winningPercentage: number;
  playoffSeed: number;
  finalStandingsPosition: number;
  logoURL: string;
}

export async function getBoxScore(): Promise<BoxScore[]> {
  // const periodId = 5;
  const res = await fetch(
    `https://us-central1-gialbc.cloudfunctions.net/getBoxscoreForWeek` //?periodId=${periodId}`
  );
  return res.json();
}

export async function getTeams(): Promise<Team[]> {
  // const periodId = 5;
  const res = await fetch(
    `https://us-central1-gialbc.cloudfunctions.net/getTeamsForWeek` //?periodId=${periodId}`
  );
  return res.json();
}

export interface NFLTeam {
  id: number;
  team: string;
  teamAbbrev: string;
  record: string;
  score: number;
}

export interface NFLTeam {
  id: number;
  team: string;
  teamAbbrev: string;
  record: string;
  score: number;
}

export interface NFLGame {
  startTime: Date;
  quarter: number;
  clock: string;
  odds: string;
  broadcaster: string;
  gameStatus: "Not Started" | "Final" | "In Progress";
  homeTeam: NFLTeam;
  awayTeam: NFLTeam;
}

export async function getNFLGamesForPeriod(params: {
  startDate: string;
  endDate: string;
}): Promise<NFLGame[]> {
  const res = await fetch(
    `https://us-central1-gialbc.cloudfunctions.net/getNFLGamesForPeriod?startDate=${params.startDate}&endDate=${params.endDate}`
  );
  return res.json();
}

import { useEffect, useState } from "react";
import { Team, getTeams } from "../lib/helpers";

export function useTeams() {
  const [teams, setTeams] = useState<Team[]>([]);

  useEffect(() => {
    getTeams().then(setTeams);
  }, []);

  return { data: teams };
}

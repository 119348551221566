import * as React from "react";
import { ScrollView, Image, StyleSheet } from "react-native";

import EditScreenInfo from "../components/EditScreenInfo";
import { Text, View } from "../components/Themed";

function Blink() {
  return (
    /* @ts-expect-error */
    <blink
      style={{
        fontSize: 30,
        fontWeight: "semibold",
        alignSelf: "center",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      Welcome to the GIALB Club Website
      {/* @ts-expect-error */}
    </blink>
  );
}

const YOUTUBE_IDS = [
  "QOJyXF_eF2U",
  "ceeerxFJpJs",
  "ZsYtIk09FMA",
  "fEp2Mh25Jqc",
];

export function StateOfTheLeagueScreen() {
  return (
    <ScrollView style={{ flex: 1 }} contentContainerStyle={styles.container}>
      {YOUTUBE_IDS.map((id) => (
        <iframe
          key={id}
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ))}
      <div style={{ height: 560 }}>
        <Text style={{ fontWeight: "bold" }}>state of the league wk 2</Text>
        <iframe
          src="https://streamable.com/e/k56non"
          frameBorder="0"
          width="100%"
          height="100%"
          allowFullScreen
        />
      </div>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, padding: 40 },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});

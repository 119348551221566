import React, {
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useState,
} from "react";
import {
  ScrollView,
  StyleSheet,
  ActivityIndicator,
  useWindowDimensions,
  AppState,
  AppStateStatus,
  Button,
} from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import { keyBy } from "lodash";
import { Audio } from "expo-av";

import { Text, View } from "../components/Themed";
import { useTeams } from "../hooks/useTeams";
import { BoxScore, useBoxScores } from "../hooks/useBoxScores";
import { useNFLGames } from "../hooks/useNFLGames";
import type { Team, NFLGame } from "../lib/helpers";
import { useFocusEffect } from "@react-navigation/native";

const WELCOME_AUDIOS = [
  require("../assets/audio/welcome-1.mp3"),
  require("../assets/audio/welcome-2.mp3"),
  require("../assets/audio/welcome-3.mp3"),
  require("../assets/audio/welcome-4.mp3"),
  require("../assets/audio/welcome-giulianocast.mp3"),
  require("../assets/audio/hi-from-commish.mp3"),
];

const TIP_AUDIOS = [
  require("../assets/audio/tip-intro.mp3"),
  require("../assets/audio/tip-1.mp3"),
  require("../assets/audio/tip-2.mp3"),
  require("../assets/audio/tip-3.mp3"),
  require("../assets/audio/tip-4.mp3"),
  require("../assets/audio/tip-5.mp3"),
  require("../assets/audio/week-2-tip-1.mp3"),
  require("../assets/audio/week-2-tip-2.mp3"),
];

function getRandomItem<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

const positions = ["QB", "RB", "RB", "WR", "WR", "TE", "OP", "OP", "D/ST", "K"];

const positionIndexes = Object.entries(positions).reduce<
  Record<string, number>
>((carry, el) => {
  carry[el[1]] = Number.parseInt(el[0], 10);
  return carry;
}, {});

const gameTimeFormatter = new Intl.DateTimeFormat("en-US", {
  weekday: "short",
  hour: "numeric",
  minute: "numeric",
});

function BoxScoreRoster(props: { roster: BoxScore["homeRoster"] }) {
  const starters = props.roster
    .filter((entry) => entry.position !== "Bench" && entry.position !== "IR")
    .sort((a, b) => {
      const aIndex = positionIndexes[a.position];
      const bIndex = positionIndexes[b.position];
      return aIndex < bIndex ? -1 : 1;
    });
  const benchPlayers = props.roster.filter(
    (entry) => entry.position === "Bench" || entry.position === "IR"
  );
  const { gamesByTeamAbbreviation } = useContext(Context);

  return (
    <View>
      {starters.map((entry, i) => {
        const game: NFLGame | undefined =
          gamesByTeamAbbreviation[entry.player.proTeamAbbreviation];
        const isHomeGame =
          entry.player.proTeamAbbreviation === game?.homeTeam.teamAbbrev;
        const otherTeam = isHomeGame
          ? game?.awayTeam.teamAbbrev
          : game?.homeTeam.teamAbbrev;
        const time = game
          ? gameTimeFormatter.format(new Date(game.startTime))
          : "";
        const score = isHomeGame
          ? `${game?.homeTeam.score}-${game?.awayTeam.score}`
          : `${game?.awayTeam.score}-${game?.homeTeam.score}`;
        const gameStr = !game
          ? "PPD"
          : game.gameStatus === "Not Started"
          ? `${isHomeGame ? "" : "@"}${otherTeam}, ${time}`
          : `${isHomeGame ? "" : "@"}${otherTeam}, ${score} ${
              game.gameStatus === "Final" ? "Final" : ""
            }`;

        return (
          <View
            key={entry.player.id}
            style={[
              { padding: 5, justifyContent: "space-between" },
              i % 2 === 0 ? { backgroundColor: "white" } : null,
            ]}
            row
          >
            <View>
              <View row spacing={6}>
                <Text>{entry.player.fullName}</Text>
                <Text style={{ fontSize: 10 }}>
                  {entry.player.proTeamAbbreviation}
                </Text>
              </View>
              <Text style={{ fontSize: 10 }}>{gameStr}</Text>
            </View>
            <Text>{entry.totalPoints}</Text>
          </View>
        );
      })}
    </View>
  );
}

function RosterPositions() {
  return (
    <View>
      {positions.map((pos, i) => (
        <View
          key={i}
          style={[
            {
              height: 39,
              padding: 5,
              justifyContent: "center",
              alignItems: "center",
            },
            i % 2 === 0 ? { backgroundColor: "white" } : null,
          ]}
        >
          <Text>{pos}</Text>
        </View>
      ))}
    </View>
  );
}

const Context = React.createContext<{
  gamesByTeamAbbreviation: Record<string, NFLGame>;
  games: NFLGame[];
  teams: Team[];
}>({
  gamesByTeamAbbreviation: {},
  games: [],
  teams: [],
});

export function GiulianoCastScreen() {
  const { data: teams } = useTeams();
  const { data: boxScores, refetch } = useBoxScores();
  const { data: games } = useNFLGames();
  const { width } = useWindowDimensions();
  const [isInteracted, setIsInteracted] = useState(
    (global as any).isInteracted ?? false
  );

  const providerValue = useMemo(() => {
    const gamesByTeamAbbreviation = games.reduce<Record<string, NFLGame>>(
      (carry, game: NFLGame) => {
        carry[game.homeTeam.teamAbbrev] = game;
        carry[game.awayTeam.teamAbbrev] = game;
        return carry;
      },
      {}
    );
    return { games, teams, gamesByTeamAbbreviation };
  }, [games, teams]);

  useEffect(() => {
    AsyncStorage.getItem("isInteracted").then((value) => {
      setIsInteracted(!!value);
    });
  }, []);

  useFocusEffect(
    useCallback(() => {
      if (isInteracted) {
        Audio.Sound.createAsync(
          getRandomItem(WELCOME_AUDIOS),
          {
            shouldPlay: true,
          },
          (status) => {
            // console.log(status);
          }
        );
      }
    }, [isInteracted])
  );

  async function giveTip() {
    const currentIndexStr =
      (await AsyncStorage.getItem("currentTipIndex")) ?? "0";
    let currentIndex = Number.parseInt(currentIndexStr, 10);
    if (currentIndex >= TIP_AUDIOS.length - 1) currentIndex = 0;
    Audio.Sound.createAsync(TIP_AUDIOS[currentIndex], {
      shouldPlay: true,
    });
    AsyncStorage.setItem("currentTipIndex", (currentIndex + 1).toString());
  }

  useEffect(() => {
    let interval = setInterval(() => {
      refetch();
    }, 10000);

    function appStateListener(nextState: AppStateStatus) {
      if (nextState.match(/inactive|background/)) {
        clearInterval(interval);
      } else {
        refetch();
        interval = setInterval(() => {
          refetch();
        }, 10000);
      }
    }

    AppState.addEventListener("change", appStateListener);

    return () => {
      AppState.removeEventListener("change", appStateListener);
      clearInterval(interval);
    };
  }, [refetch]);

  if (teams.length === 0 || boxScores.length === 0) {
    return <ActivityIndicator />;
  }

  if (!isInteracted) {
    return (
      <View>
        <Button
          onPress={() => {
            AsyncStorage.setItem("isInteracted", "true");
            setIsInteracted(true);
          }}
          title="Load GiulianoCast™"
        />
      </View>
    );
  }

  console.log({ teams, boxScores, games, providerValue });
  const teamsById = keyBy(teams, "id");

  return (
    <Context.Provider value={providerValue}>
      <ScrollView style={{ flex: 1 }} contentContainerStyle={styles.container}>
        <Text style={{ alignSelf: "center", textAlign: "center", padding: 20 }}>
          All hail our fearless commish, Viraj, for leading us this season
          through troubling times.
        </Text>
        <View style={{ alignItems: "center" }}>
          <Button
            onPress={() => {
              giveTip();
            }}
            title="Give me a tip!"
          />
        </View>
        <View
          row
          style={{
            justifyContent: "space-around",
            alignItems: "stretch",
            padding: 20,
            flexWrap: "wrap",
          }}
        >
          {boxScores.map((boxScore) => {
            return (
              <View
                key={`${boxScore.homeTeamId}+${boxScore.awayTeamId}`}
                style={{
                  width: Math.min(500, width),
                  borderWidth: 1,
                  marginBottom: 40,
                }}
              >
                <View row spacing={1} style={{ alignItems: "stretch" }}>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        padding: 10,
                        textAlign: "center",
                        fontSize: 13,
                      }}
                    >
                      {teamsById[boxScore.homeTeamId].name}
                    </Text>
                    <Text style={{ fontSize: 30, textAlign: "center" }}>
                      {boxScore.homeScore}
                    </Text>
                    <BoxScoreRoster roster={boxScore.homeRoster} />
                  </View>
                  <View style={{ marginTop: 71 }}>
                    <RosterPositions />
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        padding: 10,
                        textAlign: "center",
                        fontSize: 13,
                      }}
                    >
                      {teamsById[boxScore.awayTeamId].name}
                    </Text>
                    <Text style={{ fontSize: 30, textAlign: "center" }}>
                      {boxScore.awayScore}
                    </Text>
                    <BoxScoreRoster roster={boxScore.awayRoster} />
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      </ScrollView>
    </Context.Provider>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1 },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});

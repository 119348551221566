import { MaterialIcons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import { TabOneScreen } from "../screens/TabOneScreen";
import { GiulianoCastScreen } from "../screens/GiulianoCastScreen";
import { StateOfTheLeagueScreen } from "../screens/StateOfTheLeagueScreen";
import TabTwoScreen from "../screens/TabTwoScreen";
import { BottomTabParamList, TabOneParamList, TabTwoParamList } from "../types";
import { View } from "react-native";
import { Link } from "@react-navigation/native";

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName="TabOne"
      tabBarOptions={{ activeTintColor: Colors[colorScheme].tint }}
    >
      <BottomTab.Screen
        name="TabOne"
        component={TabOneNavigator}
        options={{
          title: "Home",
          tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="TabTwo"
        component={TabTwoNavigator}
        options={{
          title: "The Teams",
          tabBarIcon: ({ color }) => <TabBarIcon name="people" color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: string; color: string }) {
  return <MaterialIcons size={30} style={{ marginBottom: -3 }} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const TabOneStack = createStackNavigator<TabOneParamList>();

const Header = () => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Link to="/" style={{ padding: 20 }}>
        GIALBC
      </Link>
      <Link to="/state-of-the-league" style={{ padding: 20 }}>
        State of the League
      </Link>
      <Link to="/giuliano-cast" style={{ padding: 20 }}>
        GiulianoCast™
      </Link>
    </View>
  );
};

export default function TabOneNavigator() {
  return (
    <TabOneStack.Navigator>
      <TabOneStack.Screen
        name="Home"
        component={TabOneScreen}
        options={{ headerTitle: "GIALBC", title: "GIALBC", header: Header }}
      />
      <TabOneStack.Screen
        name="StateOfTheLeague"
        component={StateOfTheLeagueScreen}
        options={{
          headerTitle: "State of the League",
          title: "State of the League",
          header: Header,
        }}
      />
      <TabOneStack.Screen
        name="GiulianoCast"
        component={GiulianoCastScreen}
        options={{
          headerTitle: "Giuliano Cast",
          title: "Giuliano Cast",
          header: Header,
        }}
      />
    </TabOneStack.Navigator>
  );
}

const TabTwoStack = createStackNavigator<TabTwoParamList>();

function TabTwoNavigator() {
  return (
    <TabTwoStack.Navigator>
      <TabTwoStack.Screen
        name="TabTwoScreen"
        component={TabTwoScreen}
        options={{ headerTitle: "The Teams" }}
      />
    </TabTwoStack.Navigator>
  );
}

import * as React from "react";
import { ScrollView, Image, StyleSheet, ActivityIndicator } from "react-native";
import { sortBy } from "lodash";

import { Text, View } from "../components/Themed";
import { useTeams } from "../hooks/useTeams";

function Blink() {
  return (
    <div
      className="blink"
      style={{
        fontSize: 30,
        fontWeight: 600,
        alignSelf: "center",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      Welcome to the GIALB Club Website
    </div>
  );
}

export function TabOneScreen() {
  const { data } = useTeams();
  const sorted = sortBy(data, ["playoffSeed"]);
  return (
    <ScrollView style={{ flex: 1 }} contentContainerStyle={styles.container}>
      <Blink />
      <Image
        source={require("../assets/images/nfl_collage.jpg")}
        style={{ height: 200 }}
        resizeMode="center"
      />
      <Text style={{ alignSelf: "center", textAlign: "center", padding: 20 }}>
        All hail our fearless commish, Viraj, for leading us this season through
        troubling times.
      </Text>
      <View style={{ padding: 20 }}>
        <table
          style={{ padding: 20 }}
          className="pure-table pure-table-horizontal pure-table-striped"
        >
          <thead>
            <tr>
              <th>Team</th>
              <th>Wins</th>
              <th>Losses</th>
              <th>Ties</th>
            </tr>
          </thead>
          <tbody>
            {sorted.length ? (
              sorted.map((team) => (
                <tr key={team.id}>
                  <td>{team.name}</td>
                  <td>{team.wins}</td>
                  <td>{team.losses}</td>
                  <td>{team.ties}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>
                  <ActivityIndicator />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1 },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});

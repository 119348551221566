import * as React from "react";
import { Text as DefaultText, View as DefaultView } from "react-native";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";

export function useThemeColor(
  props: { light?: string; dark?: string },
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  } else {
    return Colors[theme][colorName];
  }
}

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
};

export type TextProps = ThemeProps & DefaultText["props"];
export type ViewProps = { row?: boolean; spacing?: number } & ThemeProps &
  DefaultView["props"];

export function Text(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, "text");

  return <DefaultText style={[{ color }, style]} {...otherProps} />;
}

export function View(props: ViewProps) {
  const {
    style,
    lightColor,
    darkColor,
    row,
    children,
    spacing,
    ...otherProps
  } = props;

  return (
    <DefaultView
      style={[
        row ? { flexDirection: "row", alignItems: "center" } : null,
        style,
      ]}
      {...otherProps}
    >
      {typeof spacing === "number"
        ? React.Children.toArray(children)
            .filter((c) => c !== null)
            .map((c, i) => (
              <React.Fragment key={(c as any)?.key ?? i}>
                {i > 0 ? (
                  <View
                    style={row ? { width: spacing } : { height: spacing }}
                  />
                ) : null}
                {c}
              </React.Fragment>
            ))
        : children}
    </DefaultView>
  );
}

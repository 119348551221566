import { useEffect, useState } from "react";
import { NFLGame, getNFLGamesForPeriod } from "../lib/helpers";

export function useNFLGames() {
  const [games, setGames] = useState<NFLGame[]>([]);

  useEffect(() => {
    getNFLGamesForPeriod({
      startDate: "20201007",
      endDate: "20201014",
    }).then(setGames);
  }, []);

  return { data: games };
}
